import React, { useState } from 'react';
import CustomSelect from './CustomSelectFilter';
import { BiRefresh } from 'react-icons/bi';

function TableComponent({
    headerName,
    filters,
    columns,
    style,
    pagination = true,
    data,
    itemsPerPage: defaultItemsPerPage = 10,
    onSelectRow,
    isLoading,   // <-- Check if Data is Loading
    error       // <-- Check if there is an error when loading the data
}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [filtersState, setFiltersState] = useState((filters || []).reduce((acc, filter) => ({
        ...acc, [filter.key]: filter.defaultValue || ''
    }), {}));

    const filteredData = data.filter(item =>
        (filters || []).every(filter =>
            filtersState[filter.key] ? item[filter.key] === filtersState[filter.key] : true
        )
    );

    const currentItems = filteredData.slice(
        (currentPage - 1) * defaultItemsPerPage,
        currentPage * defaultItemsPerPage
    );

    const handleFilterChange = (key, value) => {
        setFiltersState(prev => ({ ...prev, [key]: value }));
    }

    
    return (
      <div
        style={style}
        className="border-[#f2f2f2] border rounded-2xl mt-14 py-6 mb-10"
      >
        {/* Filters */}
        {headerName && filters && (
          <div className="mb-4 px-6 flex items-center jutify-between pb-2">
            <div className="flex items-center space-x-6">
              {headerName && (
                <h1 className="font-aeonikmedium text-sm pr-4">{headerName}</h1>
              )}
              {filters &&
                filters.map((filter) => (
                  <CustomSelect
                    key={filter.key}
                    className={`border-[#f2f2f2] ${filter.className || ""}`}
                    value={filtersState[filter.key]}
                    onChange={(val) => handleFilterChange(filter.key, val)}
                    options={filter.options}
                    placeholder={filter.placeholder}
                  />
                ))}
            </div>
          </div>
        )}

        {/* Table */}
        <table className="w-full border-collapse border-t border-[#f2f2f2]">
          <thead>
            <tr className="border-t border-[#f2f2f2] bg-[#FAFAFB]">
              {columns.map((col) => (
                <th
                  key={col.key}
                  className={`text-start  font-aeonikregular text-[#8492A6] text-sm py-4 px-6 ${col.headerClassName}`}
                >
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center px-6 py-4 font-aeonikregular text-sm"
                >
                  Loading...
                </td>
              </tr>
            )}

            {error && (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center px-6 py-4 font-aeonikregular text-sm text-red-500"
                >
                  Error: {error.message}
                </td>
              </tr>
            )}

            {!isLoading &&
              !error &&
              currentItems.map((item, idx) => (
                <tr
                  key={idx}
                  className="border-t border-[#f2f2f2] font-aeonikregular text-[#8492A6] text-xs"
                >
                  {columns.map((col) => (
                    <td
                      key={col.key}
                      className="py-4 px-6 cursor-pointer"
                      onClick={() => onSelectRow && onSelectRow(item)}
                    >
                      {col.render ? col.render(item) : item[col.key]}
                    </td>
                  ))}
                </tr>
              ))}

            {!isLoading && !error && !currentItems.length && (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-start px-6 py-4 font-aeonikregular text-sm"
                >
                  No table data available
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination */}

        {pagination && (
          <div className="mt-6 mx-6 inline-flex flex-wrap justify-end items-center space-x-2 bg-[#fff] border border-[#f2f2f2] py-2 px-2 rounded-full overflow-y-auto max-w-[90%]">

              {(() => {
                  const maxButtons = 4; 
                  const totalButtons = Math.ceil(
                    filteredData.length / defaultItemsPerPage
                  );
                  const startPage = Math.max(
                    currentPage - Math.floor(maxButtons / 2),
                    1
                  );
                  const endPage = Math.min(
                    startPage + maxButtons - 1,
                    totalButtons
                  );
                  
                return [...[...Array(endPage - startPage + 1)].map((_, index) => {
                    const pageNumber = startPage + index;
                    return (
                      <button
                        key={pageNumber}
                        className={`border-[#f2f2f2] p-2 flex items-center justify-center text-sm ${
                          currentPage === pageNumber
                            ? "bg-gray-200 rounded-full font-aeonikregular h-7 w-7 text-center"
                            : "font-aeonikregular h-7 w-7 text-center"
                        }`}
                        onClick={() => setCurrentPage(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    );
                  }), (endPage > currentPage && <button
                        className={`border-[#f2f2f2] p-2 flex items-center justify-center text-sm cursor-default font-aeonikregular h-7 w-7 text-center`}
                      >
                        ....
                      </button>)]
                  
              })()}
          </div>
        )}
      </div>
    );
}

export default TableComponent;
