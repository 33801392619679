import { patch, get, defaults, AxiosError } from "axios";
import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

const UpdateWeb = () => {
  defaults.baseURL = "https://main.majorlink.co/api";

  const [data, setData] = React.useState([]);

  const [sell, setSell] = React.useState();
  const [buy, setBuy] = React.useState();

  const [isloading, setIsLoading] = useState(false);

  const [options, setOption] = React.useState([]);

  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
    const init = async () => {
      const { data: dx } = await get("/services/list", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setData(dx);

      const opx = dx
        .filter((item) => item.type !== "GiftCard")
        .map(({ name, id }, idx) => ({ idx, id, value: name, label: name }));

      setOption(opx);
    };

    init();
  }, [update]);

  const [buyOption, setBuyOption] = React.useState();
  const [sellOption, setSellOption] = React.useState();

  const handleChanged = (e, type = "buy") => {
    const { web_sell, web_buy } = data[e.idx];

    if (type === "sell") {
      setSell(web_sell);
      setSellOption(e);
    } else {
      setBuy(web_buy);
      setBuyOption(e);
    }
  };

  const updateRate = async (type = "buy") => {
    const newx = {};

    const { token } = JSON.parse(localStorage.getItem("user"));
    let selected;
    if (type === "buy") {
      newx["web_buy"] = buy;
      selected = buyOption;
    } else if (type === "sell") {
      newx["web_sell"] = sell;
      selected = sellOption;
    }

    try {
      setIsLoading(true);
      await patch(`/admin/services/${selected.id}`, newx, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setUpdate(newx);
      setIsLoading(false);

      toast.success(`${type} Web rate updated successfully`);
    } catch (err) {
      setIsLoading(false);
      if (err.response) {
        toast.error("something went wrong, please try again later");
      }
    }
  };

  // handle selection

  return (
    //Main Container
    <div className="md:mt-10">
      <div className="md:flex pt-5">
        <div className="font-gilroysemibold text-sm text-black rounded-md p-4 border border-[#f2f2f2] md:mr-10 md:w-[86%]">
          <h2 className="text-xl text-black font-aeonikmedium">
            Web Buy Rates
          </h2>
          <p className="mb-3 text-black font-aeonikregular">
            Update your buy rates for the web trading.
          </p>
          <Select
            onChange={(e) => handleChanged(e)}
            isClearable={false}
            value={buyOption}
            name="colors"
            options={options}
            className="react-select"
            classNamePrefix="select"
          />
          <div className="flex mt-3 items-center">
            <input
              step={"any"}
              onChange={(w) => setBuy(w.target.value)}
              value={buy}
              className="rounded-md p-3 text-black w-full border border-[#f2f2f2]"
              type="number"
            />
            <button
              disabled={isloading}
              onClick={() => updateRate()}
              className="bg-primary text-white min-w-[130px] p-3 ml-2 rounded-md"
            >
              {isloading ? "Loading" : "Update"}
            </button>
          </div>
        </div>
        {/* Update rate Card */}

        {/* Update rate Card */}
        <div className="font-gilroysemibold text-sm text-black rounded-md p-4 border border-[#f2f2f2] md:w-[86%] md:mt-0 mt-8">
          <h2 className="text-xl text-black font-aeonikmedium">
            Web Sell Rates
          </h2>
          <p className="mb-2 text-black font-aeonikregular">
            Update your sell rates for the web trading
          </p>
          <Select
            onChange={(e) => handleChanged(e, "sell")}
            isClearable={false}
            name="colors"
            value={sellOption}
            options={options}
            className="react-select"
            classNamePrefix="select"
          />
          <div className="flex mt-3 items-center">
            <input
              step={"any"}
              onChange={(w) => setSell(w.target.value)}
              value={sell}
              className="rounded-md p-3 text-black w-full border border-[#f2f2f2]"
              type="number"
            />
            <button
              disabled={isloading}
              onClick={() => updateRate("sell")}
              className="bg-primary text-white min-w-[130px] p-3 ml-2 rounded-md"
            >
              {isloading ? "Loading" : "Update"}
            </button>
          </div>
        </div>
        {/* Update rate Card */}
      </div>
    </div>
    //Main Container
  );
};

export default UpdateWeb;
