import React, { useEffect, useState } from "react";

function UpdateCountryModal({ isOpen, onClose, onSubmit, item }) {
  const [buy, setBuy] = useState("");
  const [sell, setSell] = useState("");

  useEffect(() => {
    // Update the buy and sell values if item changes
    if (item) {
      setBuy(item.buy);
      setSell(item.sell);
    }
  }, [item]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = JSON.stringify({
      buy, sell, "_method": "PATCH"
    });

    onSubmit(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-xl border border-[#f2f2f2]">
        <h2 className="text-xl mb-4 font-grifter">Update {item?.name} Rate</h2>
        <form onSubmit={handleSubmit}>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">
              Buy Rate
            </label>
            <input
              type="text"
              value={buy}
              onChange={(e) => setBuy(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md font-aeonikmedium"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 font-aeonikmedium">
              Sell Rate
            </label>
            <input
              type="text"
              value={sell}
              onChange={(e) => setSell(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md font-aeonikmedium"
              required
            />
          </div>

          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-[#2752E7] text-white py-2 px-10 rounded-md font-aeonikmedium text-sm"
            >
              Update
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-red-500 text-white py-2 px-10 rounded-md font-aeonikmedium text-sm"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateCountryModal;
